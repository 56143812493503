// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './TermsAndConditions.style';

function TermsAndConditions(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const showLine = useMediaQuery('(max-width:967px)');
    const showDottedLine = useMediaQuery('(max-width:1310px)');
    const mobile = useMediaQuery('(max-width:500px)');

    return(
        <Box className={classes.root}>
                <Box className={classes.titleContainer}>
                    <p className={classes.title}>Términos</p>
                    <p id='bot' className={classes.title}>y Condiciones</p>
                </Box>

                <Box className={classes.section}>

                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>1)</p>
                        <p className={classes.subtitle}>
                            Obligatoriedad, consentimiento y aceptación
                            de los términos y condiciones.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text} >
                            Estos términos y condiciones de uso (en adelante los "Términos y Condiciones")
                            regulan el uso y funcionamiento
                            entre cualquier persona física (en adelante el "Cliente")
                            y Tarjetas Cuenca, S.A. de C.V. ( en adelante “Tarjetas Cuenca”),
                            con relación al uso de la Tarjeta de Crédito (en adelante la “Tarjeta”)
                            emitida al amparo Contrato de Apertura de Crédito que
                            celebran el Cliente y Tarjetas Cuenca (conjuntamente, las "Partes").
                            De igual forma los presentes Términos y Condiciones
                            regulan el funcionamiento de la Aplicación de Tarjetas
                            Cuenca, así como los derechos y obligaciones del Cliente
                            y de Tarjetas Cuenca (conjuntamente, las "Partes") al utilizar
                            los Servicios establecidos en el numeral 3 de los presentes
                            Términos y Condiciones (conjuntamente, los "Servicios").
                        </p>
                        <p className={classes.text}>
                            El Cliente debe leer, entender y aceptar de forma expresa todas
                            las condiciones establecidas en estos Términos y Condiciones
                            y demás políticas y principios incorporados a los mismos por
                            referencia, previo a su registro como Cliente y/o la utilización
                            de los Servicios, mediante el proceso establecido en la Aplicación.
                            De igual forma para poder solicitar la Tarjeta y utilizar los
                            Servicios ofrecidos mediante la Aplicación, deberá leer y aceptar
                            los siguientes documentos:
                        </p>

                        {/* List section */}
                            <p className={classes.text} style={{ marginBottom: 0 }}>
                                1.- Contrato de Apertura de Crédito entre Tarjetas Cuenca y el Cliente.
                            </p>
                            <hr />
                            <p className={classes.text} style={{ marginBottom: 0 }}>
                                2.- Aviso de Privacidad.
                            </p>
                            <hr />
                            <p className={classes.text} style={{ marginBottom: 0 }}>
                                3.- Formato de Domiciliación.
                            </p>
                            <hr />
                        {/* List section */}

                        <p className={classes.text}>
                        El Cliente, mediante la aceptación en la Aplicación de los presentes Términos y Condiciones, aprueba expresamente y manifiesta su consentimiento a los derechos y obligaciones contenidas en los mismos.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>A)</p>
                        <p className={classes.subSection} >
                            DECLARACIONES DEL USUARIO. USOS PROHIBIDOS Y RESTRICCIONES DE LA APLICACIÓN.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text} >
                            El Cliente manifiesta que el objeto por el cual hace uso de la Tarjeta
                            no infringe ninguna ley aplicable, ni es por un servicio, venta
                            o transmisión que esté prohibida por la ley o los presentes Términos
                            y Condiciones, tales como lo son, de manera enunciativa mas no
                            limitativa:
                            (i) tráfico de armas, de personas, de animales, etc.;
                            (ii) lavado de dinero y/o financiamiento al terrorismo;
                            (iii) pornografía, prostitución o pedofilia; y/o
                            (iv) cualquier tipo de actividad que pueda ser considerada fraudulenta,
                            ilegal o sospechosa de serlo.
                        </p>
                        <p className={classes.text}>
                            Si Tarjetas Cuenca considera que hay una sospecha o indicio en la
                            utilización de la Tarjeta para alguna actividad prohibida por la ley
                            o los presentes Términos y Condiciones, podrá rechazar, cancelar o
                            suspender la transacción, y/o bloquear temporalmente el acceso y uso
                            de la Tarjeta  y/o la utilización o disponibilidad de funcionalidades
                            y/o dar por terminado el contrato de crédito y a su vez dar por
                            terminado el uso de la Tarjeta.
                        </p>
                        <p className={classes.text}>
                            En tal caso, el Cliente responderá de todo daño y/o perjuicio que
                            pudiera ocasionar a Tarjetas Cuenca, así como sus funcionarios,
                            empleados, directores, agentes, y/o empleados, por el uso indebido
                            de la Tarjeta o a cualquier tercero.
                            Tarjetas Cuenca se reserva el derecho de iniciar las acciones judiciales
                            o extrajudiciales que estime pertinentes.
                        </p>
                        <p className={classes.text}>
                            El Cliente será exclusiva e ilimitadamente responsable por los daños
                            y perjuicios que su conducta pueda causar a Tarjetas Cuenca o a los
                            demás Clientes de los Servicios de Tarjetas Cuenca, así como por cualquier
                            otra responsabilidad de índole administrativa, penal, civil, laboral o
                            cualquier otra que su conducta o actividad pudiera causar.
                        </p>
                        <p className={classes.text}>
                            El Cliente conoce la existencia de estafas utilizadas en la red, para
                            realizar transacciones ilícitas como smishing, phishing, pharming, etc.
                            Por esta razón, es responsabilidad de cada cliente implementar las medidas
                            correspondientes para evitar ser víctima de cualquiera de estos delitos.
                        </p>
                        <p className={classes.text}>
                            Tarjetas Cuenca podrá solicitar información adicional para verificar la
                            licitud de las transacciones realizadas mediante la utilización de los
                            Servicios de Tarjetas Cuenca.
                        </p>
                        <p className={classes.text}>
                            Además, el Cliente acepta que Tarjetas Cuenca podrá hacer valer vía
                            judicial sus derechos y reclamaciones, siendo admisibles como prueba
                            estos Términos y Condiciones, así como toda la información que conste
                            en las bases de datos de Tarjetas Cuenca para evidenciar la relación
                            con el Cliente y los adeudos que se tengan, en caso de que aplique.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>B)</p>
                        <p className={classes.subSection} >
                            DOCUMENTOS APLICABLES.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text} >
                            El Crédito otorgado al Cliente se regirá por lo establecido en las cláusulas
                            del Contrato de Apertura de Crédito, celebrado entre el Cliente y Tarjetas
                            Cuenca, y en lo no especificado dentro del mismo se regirá por lo establecido
                            en los presentes Términos y Condiciones. El uso de la Aplicación y servicios
                            relacionados con ella, se regirá exclusivamente por lo establecido en el presente documento.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>

                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>2)</p>
                        <p className={classes.subtitle}>
                            Otorgamiento de Crédito
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text} >
                            El cliente acepta que, para poder hacer uso de la Tarjeta, deberá celebrar con Tarjetas Cuenca,
                            un contrato de apertura de crédito, mismo que forma parte del proceso de registro en la
                            sección de la Aplicación de Tarjetas Cuenca.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>A)</p>
                        <p className={classes.subSection} >
                            NIVELES DE CRÉDITO.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente acepta que en un primer momento el límite de la línea de crédito disponible se
                            determinará tomando como referencia el monto que de momento en momento mantenga en su cuenta
                            de fondos de pago electrónico con Cuenca Tecnología Financiera, S.A. de C.V. (Cuenca IFPE).
                        </p>
                        <p className={classes.text}>
                            Posteriormente y a decisión exclusiva de Tarjetas Cuenca, y la futura aceptación del cliente,
                            el monto de la línea de crédito podrá ser mayor al monto que mantiene como saldo de la cuenta
                            de fondos de pago electrónico y el cual se toma como referencia, siendo entera responsabilidad
                            del Cliente realizar el pago en tiempo y forma respecto de la línea de crédito utilizada,
                            sabiendo el Cliente que en caso de incumplimiento en el pago le asistirá el derecho a Tarjetas
                            Cuenca al cobro de intereses moratorios, así como de reportar al Buró de Crédito dicha circunstancia
                            y además podrá exigirle judicial o extrajudicialmente el pago de la cantidad principal así como
                            los accesorios que pudieren generarse conforme a lo pactado entre las Partes.
                        </p>
                        <p className={classes.text}>
                            De igual forma el Cliente acepta que el otorgamiento del crédito será reportado a las sociedades
                            de información crediticia correspondiente.
                        </p>
                    </Box>


                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>B)</p>
                        <p className={classes.subSection} >
                            DOMICILIACIÓN.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            De igual forma, para poder hacer uso de la Tarjeta, el Cliente, deberá aceptar el contenido en
                            el formato de Domiciliación anexo al Contrato de Apertura de Crédito celebrado con Tarjetas Cuenca.
                            Lo anterior, para efectos de que Cuenca IFPE pueda realizar el cargo en la cuenta de fondos de
                            pago electrónico del cliente y envíe los recursos a Tarjetas Cuenca.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>C)</p>
                        <p className={classes.subSection} >
                            REPOSICIÓN DE TARJETA.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El cliente podrá realizar UNA reposición de tarjeta en caso de robo o extravío sin costo, esta
                            reposición será mediante la entrega de la tarjeta con diseño azul.
                        </p>
                        <p className={classes.text}>
                            A partir de la segunda reposición o, en caso de solicitar una tarjeta con diseño edición especial,
                            se cobrará una comisión por reposición/sustitución, respectivamente de $250.00 MXN IVA incluido.
                        </p>
                        <p className={classes.text}>
                            En la segunda reposición, el cliente podrá elegir entre el diseño azul o alguna tarjeta edición especial.
                        </p>
                    </Box>
                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>D)</p>
                        <p className={classes.subSection} >
                            ENVÍO DE TARJETA FÍSICA.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            En todos los envíos de tarjeta física a cualquier parte de la República Mexicana,
                            se cobrará una comisión de $100.00 MXN IVA incluido.
                        </p>
                    </Box>

                </Box>

                <Box className={classes.section}>

                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>3)</p>
                        <p className={classes.subtitle}>
                            Aplicación
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>A)</p>
                        <p className={classes.subSection} >
                            SERVICIOS
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Tarjetas Cuenca pone a disposición del cliente la aplicación para teléfonos móviles (“Aplicación”), mediante la
                            cual el Cliente podrá: (los “Servicios”):
                        </p>

                        <p className={classes.text} style={{ marginBottom: 0}}>i) Consultar de movimientos realizados con la Tarjeta</p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>ii) Consultar la información de su Tarjeta física o virtual</p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>iii) Bloquear/Desbloquear la Tarjeta</p>
                        {showLine ? <hr /> : <br/>}
                        <p className={classes.text} style={{ marginBottom: 0}}>iv) Acceder al Servicio de Atención a Clientes de Tarjetas Cuenca</p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>v) Usar cualquier otro servicio que de tiempo en tiempo se ofrezca a través de la Aplicación.</p>
                        <hr />

                        <p className={classes.text} style={{ marginTop: '30px' }}>
                            Tarjetas Cuenca realizará directamente y será responsable de todos los procesos relacionados con el uso de la
                            Tarjeta, como lo son: procesamiento, autorización y liquidación de las Operaciones.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>B)</p>
                        <p className={classes.subSection} >
                            USO DE LA APLICACIÓN.
                        </p>
                    </Box>

                    <Box className={classes.subSectionIndexContainer}>
                        <p className={classes.subSectionIndex}>
                            I) ACCESO Y CONTRASEÑAS
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Se aclara que, el uso de la línea de crédito otorgada es personal, intransferible, y será asignada a un solo
                            Cliente y está prohibida su cesión, venta o transferencia bajo ningún título.
                            El Cliente no podrá permitir ni autorizar el uso de su Tarjeta por terceras personas.
                        </p>
                    </Box>

                    <Box className={classes.subSectionIndexContainer}>
                        <p className={classes.subSectionIndex}>
                            II) RESPONSABILIDAD DEL CLIENTE EN RELACIÓN CON SUS CLAVES Y CONTRASEÑAS
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El acceso a la Tarjeta y los Servicios será a través de la Aplicación y se realizará mediante el ingreso de
                            una contraseña creada por el Cliente con anterioridad o mediante el ingreso de biométricos (huella, reconocimiento facial, etc.).
                        </p>
                        <p className={classes.text}>
                            El uso y protección de la contraseña del Cliente será responsabilidad de este, la cual deberá ser memorizada y guardarla bajo la
                            más estricta confidencialidad, en el entendido de que no podrá compartirla con terceros y evitará mandar información confidencial
                            por correo electrónico, páginas de internet no seguras, redes sociales, etc.
                        </p>
                        {!mobile && <br />}
                        {!mobile && <br />}
                        <p className={classes.text}>
                            Tarjetas Cuenca únicamente requerirá al Cliente que ingrese su contraseña para: i) ingresar a la Aplicación; ii) Autorizar Operaciones;
                            iii) Para cambiar información de la Tarjeta. Por lo que, Tarjetas Cuenca nunca solicitará al Cliente su contraseña o NIP, números de
                            Tarjeta o saldos, o datos personales por ninguno de los medios no seguros como lo son: correo electrónico, páginas web no oficiales,
                            o redes sociales no verificadas.
                        </p>
                        <p className={classes.text}>
                            En caso de el Cliente pierda, extravíe u olvide su contraseña para el acceso a la Aplicación, deberá seguir el procedimiento que se señala
                            en las secciones siguientes.
                        </p>
                    </Box>

                    <Box className={classes.subSectionNested}>
                        <p className={classes.subSectionIndexNested}>
                            (1) Proceso de restablecimiento de contraseñas y claves
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            En caso de que el Cliente necesite cambiar sus contraseñas o haya olvidado las mismas deberá seguir el proceso siguiente:
                        </p>
                        <p className={classes.subSectionIndexLetters}>
                            (a) Por medio del Servicio de Atención al Cliente
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0 }}>
                            a) Contactar al Servicio de Atención al Cliente, solicitando iniciar el proceso de restablecimiento de contraseña o clave, a través
                            de uno o varios de los medios siguientes: (i) A través de la Aplicación; o (ii) A través de la aplicación de mensajería instantánea
                            denominada “WhatsApp” por medio de la cuenta de empresa oficial verificada de Tarjetas cuenca, registrada con el numero telefónico del Cliente.
                        </p>
                        <hr className={classes.dottedLine} />
                        <p className={classes.text} style={{ marginBottom: 0 }}>
                            b) Un miembro de Atención al Cliente le solicitará comprobar su identidad, mediante un video en el cual el cliente deberá sostener su identificación
                            y mencionar su nombre, la fecha y su solicitud.
                        </p>
                        <hr className={classes.dottedLine} />
                        <p className={classes.text} style={{ marginBottom: 0 }}>
                            c) El miembro autorizado de Atención al Cliente levantará un comprobante con un número de referencia “ticket” e iniciará el procedimiento interno
                            para restablecer la contraseña.
                        </p>
                        {showDottedLine && <hr className={classes.dottedLine} />}
                        <p className={classes.text} style={{ marginBottom: 0 }}>
                            d) Una vez que la contraseña se haya restablecido en los Sistemas de Tarjetas Cuenca, el Cliente recibirá una notificación informándole que deberá entrar
                            a su Aplicación para poder crear una nueva contraseña.
                        </p>
                        <hr className={classes.dottedLine} />
                        <p className={classes.text} style={{ marginBottom: 0 }}>
                            e) Una vez creada la nueva contraseña, únicamente el Cliente la sabrá, y la anterior contraseña dejará de ser válida.
                        </p>
                        <hr className={classes.dottedLine} />
                        <br/>
                        <p className={classes.subSectionIndexLetters} >
                            (b) Personas de confianza
                        </p>
                        <hr />
                        <p className={classes.text}>
                            Tarjetas Cuenca le ofrece a sus Clientes el servicio de “Personas de Confianza”, el cual consiste en elegir 3 personas dentro de tu círculo de confianza, las cuales te podrán apoyar a:
                        </p>
                        <p className={classes.text} style={{ marginBottom: 0 }}>
                            Poner la Tarjeta en modo seguro en caso de robo o extravío
                        </p>
                        <hr className={classes.dottedLine} />
                        <p className={classes.text}>
                            Los contactos seleccionados pueden ser cambiados cuándo y cuántas veces lo desee el Cliente.
                        </p>

                    </Box>

                    <Box className={classes.subSectionNested}>
                        <p className={classes.subSectionIndexNested}>
                            (2) Poner la Tarjeta en modo seguro en caso de robo o extravio
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente afectado deberá contactar a una de sus personas de confianza, para que este último, ingresando a su propia aplicación pueda poner en modo
                            seguridad la Tarjeta del Cliente afectado, lo cual significa que ninguna transacción podrá ser realizada a través ésta. Para poder reactivar la Tarjeta,
                            el Cliente afectado directamente deberá de ponerse en contacto con el Servicio de Atención al Cliente o mediante el ingreso de su Aplicación para activarla.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>C)</p>
                        <p className={classes.subSection} >
                            FACTORES DE AUTENTIFICACIÓN Y MEDIDAS DE SEGURIDAD PARA AUTORIZACIÓN DE OPERACIONES CON LA TARJETA.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                        Tarjetas Cuenca contará con sistemas y medidas de seguridad que permitan que únicamente el Cliente, mediante el uso de sus contraseñas y autorizaciones en la Aplicación,
                        pueda permitir la autorización de Operaciones.
                        El uso de las medidas de seguridad antes mencionados constituirán indicios de que el Cliente es quien directamente realiza dichas Operaciones.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>D)</p>
                        <p className={classes.subSection} >
                        ACLARACIONES.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Una aclaración será el mecanismo mediante el cual se solicita la revisión de cualquier operación relacionada con el Servicio proporcionado por Tarjetas Cuenca.
                        </p>
                        <p className={classes.text}>
                            Tarjetas Cuenca contará con un equipo encargado de analizar cualquier tipo de aclaraciones, para lo cual el Cliente deberá enviar un mensaje presentado dicha aclaración mediante un mensaje enviado
                            a través de la aplicación de mensajería instantánea denominada “WhatsApp” por medio de la cuenta de empresa oficial verificada de Tarjetas Cuenca, registrada con el numero telefónico del Cliente
                            o mediante el acceso directo a dicho sistema de mensajería disponible en la Aplicación.
                        </p>
                    </Box>

                    <Box className={classes.subSectionIndexContainer}>
                        <p className={classes.subSectionIndex}>
                            I) CARGOS NO RECONOCIDOS
                        </p>
                    </Box>

                    <Box className={classes.textWrapper} >
                        <p className={classes.text}>
                            Cuando el Cliente envíe a Tarjetas Cuenca un aviso de cargo no reconocido, esta abonará a más tardar al segundo Día Hábil Bancario posterior a aquel en que haya recibido el aviso respectivo,
                            el monto equivalente al cargo no reconocido. Lo anterior siempre y cuando Tarjetas Cuenca haya recibido el aviso durante un periodo de noventa días naturales posteriores a la fecha en la que se realizó el cargo objeto de la reclamación.
                        </p>
                        <p className={classes.text}>
                            Tarjetas Cuenca no estará obligada a realizar el abono de recursos antes mencionado cuando compruebe que el Cliente haya utilizado al menos dos elementos de seguridad en la operación que dijo desconocer/objeto de la reclamación.
                            Tarjetas Cuenca contará con un plazo de entre 7 y 30 días hábiles para emitir el dictamen de dicha aclaración, mismo que será enviado al Cliente. El mencionado dictamen se llevará a cabo mediante la realización de una
                            investigación del medio de disposición y los factores de autentificación utilizados.
                        </p>
                        <p className={classes.text}>
                            Para efectos de la mencionada investigación Tarjetas Cuenca se reserva el derecho de solicitar información adicional al Cliente.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>E)</p>
                        <p className={classes.subSection} >
                            HORARIOS DEL SERVICIO Y ATENCIÓN AL CLIENTE.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Los Servicios ofrecidos por Tarjetas Cuenca se encuentra disponible 24 horas al día 7 días a la semana.
                        </p>
                        <p className={classes.text}>
                            El servicio de Atención al Cliente tiene un horario de lunes a viernes de 8:00 am a 10:00 pm. Y sábados y domingos de 9:00 am a 9:00 pm.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>F)</p>
                        <p className={classes.subSection} >
                            ESTADOS DE CUENTA.
                        </p>
                    </Box>

                    <Box className={classes.subSectionIndexContainer}>
                        <p className={classes.subSectionIndex}>
                            I) PERIODICIDAD Y CONSULTA DE LOS ESTADOS DE CUENTA.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Tarjetas Cuenca enviará un estado de cuenta de forma mensual al Cliente, a la dirección de correo electrónico indicado en la Aplicación, en donde le permita conocer
                            los cargos realizados con su Tarjeta. En el mismo sentido, Tarjetas Cuenca tendrá disponible el estado de cuenta de los dos meses inmediatos anteriores en la sesión del Cliente en la Aplicación.
                        </p>
                        <p className={classes.text}>
                             Los Estados de Cuenta deberán contener la información siguiente:
                        </p>
                        {!mobile && <br />}
                        {!mobile && <br />}
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            a) El total de las operaciones realizadas con la Tarjeta.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            d) Beneficios	que	en su caso	se	hayan	generado	en	el	periodo correspondiente, y
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            e) Nombre y lugar del establecimiento comercial donde se utilizó la Tarjeta, así como el monto.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            d) El monto de cada una de la operaciones realizadas con la Tarjeta.
                        </p>
                        <hr />
                    </Box>

                    <Box className={classes.subSectionIndexContainer}>
                        <p className={classes.subSectionIndex}>
                            II) MODIFICACIÓN EN EL ENVÍO DE ESTADOS DE CUENTA.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Tarjetas Cuenca notificará al Cliente: (i) A través de la Aplicación; (ii) En la dirección de correo electrónico principal registrada por el Cliente; o (iii) A través de la aplicación de mensajería instantánea denominada “WhatsApp” por medio de la cuenta de empresa oficial verificada de Tarjetas Cuenca, registrada con el numero telefónico del Cliente, en caso de modificar la forma de envío de los estados de cuenta.
                        </p>
                        <p className={classes.text}>
                             Tarjetas Cuenca solicitará el consentimiento del Cliente para aceptar el nuevo medio de envío de estados de cuenta. En el entendido que, el Cliente podrá solicitar a Tarjetas Cuenca, en cualquier momento, el envío de los estados de cuenta a su correo.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>

                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>4)</p>
                        <p className={classes.subtitle}>
                            Condiciones Generales de Contratación.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>A)</p>
                        <p className={classes.subSection} >
                            DECLARACIONES GENERALES DE CONTRATACIÓN.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Sólo podrán ser Clientes aquellas personas que tengan capacidad legal para contratar y estén debidamente
                            registrados como Clientes en Tarjetas Cuenca.
                        </p>
                        <p className={classes.text}>
                            No podrán utilizar los Servicios: menores de edad, personas que no tengan capacidad para contratar o aquellos
                            Clientes que hayan sido suspendidos o inhabilitados de Tarjetas Cuenca, temporal o definitivamente.
                        </p>
                        <p className={classes.text}>
                            El Cliente declara que en todo momento actúa por cuenta propia y, en consecuencia, únicamente tiene permitido
                            utilizar los Servicios a nombre propio y no en representación y/o por cuenta de terceros. Está prohibido que el Cliente revenda, alquile,
                            preste o por algún otro acto jurídico ceda y/o otorgue los Servicios a terceros.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>B)</p>
                        <p className={classes.subSection} >
                            PROPIEDAD INTELECTUAL.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente acepta que, de manera enunciativa mas no limitativa, el contenido de textos, gráficos, enlaces, botones, logos e imágenes,
                            así como cualesquiera otros derechos de autor y marcas, son de propiedad exclusiva de Tarjeta Cuenca y/o aliados comerciales (la “Propiedad Intelectual”).
                        </p>
                        <p className={classes.text}>
                            El Cliente se compromete a no exponer, usar, copiar o modificar la Propiedad Intelectual en modo alguno. El Cliente está autorizado únicamente para
                            ver y guardar copia de las páginas para su uso personal y nunca comercial. Además, el Cliente acepta que: (i) No se involucrará ni usará ningún
                            dispositivo automático ni minería de datos, ningún robot, araña, raspador ni ningún otro método similar de recolección o extracción de datos para tener
                            acceso o usar el Servicio; (ii) No modificará, copiará, enmarcará, alquilará, prestará, venderá, distribuirá o creará trabajos derivados del Servicio ni
                            total ni parcialmente; y (iii) No violará los derechos de autor, marcas, ni ningún otro derecho de propiedad intelectual, ni derechos de publicidad o
                            privacidad de Tarjetas Cuenca, ni de ningún tercero.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>C)</p>
                        <p className={classes.subSection} >
                            MODIFICACIONES DE TÉRMINOS Y CONDICIONES.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Tarjetas Cuenca podrá modificar en cualquier momento los Términos y Condiciones y notificará los cambios al Cliente enviándole una notificación informándole
                            de dichos cambios. Todos los términos modificados entrarán en vigor a los 5 días de su publicación.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>D)</p>
                        <p className={classes.subSection} >
                            DOMICILIOS.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Se fija como domicilio de Tarjetas Cuenca: Calle de Varsovia 36, Piso 9, Oficina 912, Colonia Juárez, Cuauhtémoc, C.P. 06600, Ciudad de México.
                        </p>
                        <p className={classes.text}>
                            El domicilio del Cliente será el domicilio registrado ante Tarjetas Cuenca en el proceso de registro.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>E)</p>
                        <p className={classes.subSection} >
                            TERMINACIÓN DEL CONTRATO.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Tarjetas Cuenca y el Cliente, de manera conjunta o individual, podrán en cualquier momento durante la vigencia de los presente los presentes Términos y
                            Condiciones, así como del uso de la Tarjeta, sin expresión de causa alguna, lo anterior, implicará la terminación del Contrato de Apertura de Crédito celebrado
                            entre el Cliente y Tarjetas Cuenca. En cuyo caso, el Cliente deberá proceder conforme lo establecido en la cláusula de terminación de dicho contrato.
                        </p>
                        <p className={classes.text}>
                            Asimismo, Tarjetas Cuenca podrá, en caso de incumplimiento del Cliente a los  presentes Términos y Condiciones o a la legislación aplicable en la materia,
                            dar por terminada la prestación del Servicio y del Contrato de Apertura de Crédito, sin ningún tipo de aviso previo, reservándose el derecho de reclamar los daños y perjuicios que tal incumplimiento le haya causado.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>F)</p>
                        <p className={classes.subSection} >
                            OTRAS DISPOSICIONES.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Si alguna disposición de estos Términos y Condiciones es inválida o inaplicable en virtud de la legislación aplicable, entonces se modificará e interpretará para cumplir los objetivos de tal disposición en la mayor
                            medida posible y en virtud de la legislación aplicable y las disposiciones restantes continuarán en plena vigencia y efecto.
                        </p>
                        <p className={classes.text}>
                            Los encabezados que se incluyen son solo para su conveniencia, y no serán considerados en la interpretación de este documento.
                        </p>
                        <p className={classes.text}>
                            Estos Términos y Condiciones no limitan los derechos que Tarjetas Cuenca pueda tener bajo secreto comercial, derechos de autor, patentes, u otras leyes aplicables.
                        </p>
                        <p className={classes.text}>
                            El fracaso por parte de Tarjetas Cuenca para hacer valer cualquier derecho o disposición, en virtud de los presentes Términos y Condiciones, no constituirá una renuncia
                            a tal derecho o disposición. Ninguna renuncia de cualquier disposición de este documento se considerará una renuncia posterior o continuada a dicha disposición o a cualquier otra.
                        </p>
                        <p className={classes.text}>
                            Los presentes Términos y Condiciones, constituyen el acuerdo íntegro de las Partes con respecto a los asuntos previstos en los mismos y deja sin efecto cualquier contrato escrito o
                            verbal celebrado por las Partes con anterioridad.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>G)</p>
                        <p className={classes.subSection} >
                            CESIÓN.
                        </p>
                    </Box>


                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Estos Términos y Condiciones y todos los derechos y licencias otorgados, no podrán ser transferidos, vendidos o cedidos por el Cliente ni por Tarjetas Cuenca. Cualquier intento de
                            cesión o venta será nulo y no tendrá efecto.
                        </p>
                    </Box>

                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>H)</p>
                        <p className={classes.subSection} >
                            LEGISLACIÓN APLICABLE Y JURISDICCIÓN
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Estos Términos y Condiciones serán interpretados de conformidad con la legislación federal vigente y aplicable en México. Las Partes convienen en someterse a la jurisdicción de los tribunales competentes en la Ciudad de México y renuncian expresamente a cualquier otra jurisdicción que les pudiera corresponder por razón de sus domicilios presentes o futuros, o por cualquier otro motivo.
                        </p>
                    </Box>


                    <Box className={classes.subsectionTitleContainer}>
                        <p className={classes.letter}>I)</p>
                        <p className={classes.subSection} >
                            DEFINICIONES.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            Aplicación: a la sección de la aplicación de uso exclusivo para los Clientes de Tarjetas Cuenca para la prestación de los Servicios.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            Contrato: Contrato de Apertura de Crédito celebrado entre el Cliente y Tarjetas Cuenca.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            Cuenta de Fondos de Pago Electrónico: a la cuenta de fondos de pago electrónico que el Cliente tenga con Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            Domiciliación: a la ejecución de transferencias de fondos, individuales o recurrentes, con cargo a una Cuenta de Fondos de Pago Electrónico que el Cliente mantiene con Cuenca Tecnología
                            Financiera, S.A. de C.V. Institución de Fondos de Pago Electrónico, que realice esta última, de acuerdo con la aceptación expresa que el titular de dicha Cuenta presente mediante Tarjetas
                            Cuenca, con la finalidad de cargar en su cuenta de fondos de pago electrónico, los fondos que el Cliente haya dispuesto con la Tarjeta.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            Tarjeta: al medio de disposición a través del cual el Cliente podrá hacer uso de la línea de crédito otorgada con base en el Contrato.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            Operaciones: a las transacciones realizadas con la Tarjeta.
                        </p>
                        <hr />
                        <p className={classes.text} style={{ marginBottom: 0}}>
                            Leídos por el Cliente los presentes Términos y Condiciones y sabedor de su alcance legal, el Cliente manifiesta expresamente a Tarjetas Cuenca la aceptación de los presentes Términos y Condiciones a través de la Aplicación.
                        </p>

                    </Box>

                </Box>
        </Box>
    )
}

export default withStyles(styles)(TermsAndConditions);
