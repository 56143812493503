// React imports
import React from 'react';

// gatbsy imports
import { graphql } from 'gatsby';

// SEO
import SEO from 'components/seo';

// Theme and layout
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';

// Page views
import TermsAndConditions from  'views/TermsAndConditions'

type DataProps = {
    site: {
        buildTime: string;
    }
};

const TerminosPage = () => (
    <WithTheme>
        <Layout>

          <SEO title='Términos y Condiciones' />

          <TermsAndConditions />

        </Layout>
    </WithTheme>
);

export default TerminosPage;

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`;
