import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    root: {
        fontWeight: 500,
        marginBottom: '330px',
        marginLeft: '30%',
        marginTop: '237px',
        [theme.breakpoints.down(1000)]: {
            width: '78%',
            marginLeft: '22%',
        },
        [theme.breakpoints.down(701)]: {
            width: '79%',
            marginLeft: '21%',
        },
        [theme.breakpoints.down(650)]: {
            marginBottom: '200px',
            marginTop: '150px',
        },
        [theme.breakpoints.down(501)]: {
            marginBottom: '85px',
            marginTop: '125px',
        },
    },
    titleContainer: {
        marginBottom: '133px',
        '& #bot': { marginLeft: '59.5px', },
        [theme.breakpoints.down(1000)]: {
            marginBottom: '70px',
            '& #bot': { marginLeft: 0, },
        },
        [theme.breakpoints.down(701)]: {
            marginBottom: '60px',
            marginTop: '25px',
        },
    },
    title: {
        fontSize: '59.57px',
        color: '#3D4975',
        letterSpacing: '5px',
        fontWeight: 300,
        marginBottom: 0,
        [theme.breakpoints.down(1000)]: {
            fontSize: '55.24px',
            letterSpacing: '6.34px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '52.24px',
            letterSpacing: '6px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '45px',
            letterSpacing: '2.5px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '35px',
            letterSpacing: '1.2px',
        },
    },
    section: {
        marginBottom: '80px',
        width: '90%',
        [theme.breakpoints.down(650)]: { marginBottom: '70px' },
        [theme.breakpoints.down(501)]: { marginBottom: '50px' },
    },
    subtitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        margin: '8em 0 70px 0',
        [theme.breakpoints.down(1000)]: {
            margin: '50px 0 40px 0',
        },
        [theme.breakpoints.down(650)]: { margin: '1em 0 40px 0' },
        [theme.breakpoints.down(501)]: { margin: '0.5em 0 20px 0' },
    },
    subsectionTitleContainer: {
        width: '93%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        margin: '5.5em 0 35px 0',
        [theme.breakpoints.down(1000)]: {
            margin: '2em 0 40px 0',
        },
        [theme.breakpoints.down(650)]: {
            margin: '35px 0 25px 0',
        },
        [theme.breakpoints.down(501)]: {
            margin: '30px 0 20px 0',
        },
    },
    number: {
        fontSize: '32px',
        color: '#3D4274',
        letterSpacing: '1.13px',
        opacity: 0.5,
        lineHeight: '45px',
        fontWeight: 500,
        marginRight: '28px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '30px',
            letterSpacing: '1.7px',
            lineHeight: '52px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '29px',
            letterSpacing: '1.5px',
            lineHeight: '42px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '26px',
            letterSpacing: '1.2px',
            lineHeight: '40px',
            marginRight: '15px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '20px',
            letterSpacing: '0.6px',
            lineHeight: '25px',
            marginBottom: 0,
        },
    },
    letter: {
        fontWeight: 900,
        fontSize: '20.3px',
        color: '#9BA0B7',
        lineHeight: '32px',
        letterSpacing: '1.6px',
        marginRight: '20px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '20px',
            lineHeight: '30px',
            letterSpacing: '1.5px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '19px',
            lineHeight: '23.5px',
            letterSpacing: '1.35px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '16.5px',
            lineHeight: '18px',
            letterSpacing: '1.2px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '12px',
            lineHeight: '13px',
            letterSpacing: '0.8',
            marginBottom: 0,
        },
    },
    subtitle: {
        fontSize: '32px',
        color: '#3D4274',
        opacity: 0.5,
        letterSpacing: '2px',
        lineHeight: '45px',
        fontWeight: 500,
        width: '100%',
        [theme.breakpoints.down(1000)]: {
            fontSize: '30px',
            letterSpacing: '1.7px',
            lineHeight: '52px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '29px',
            letterSpacing: '1.5px',
            lineHeight: '42px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '26px',
            letterSpacing: '1.2px',
            lineHeight: '40px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '20px',
            letterSpacing: '0.6px',
            lineHeight: '25px',
            marginBottom: 0,
        },
    },
    text: {
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '0.78px',
        lineHeight: '26.35px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '18px',
            letterSpacing: '0.75px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '17px',
            letterSpacing: '0.34px',
            lineHeight: '26.35px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '16px',
            letterSpacing: '0.5px',
            lineHeight: '22px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px',
            letterSpacing: '0.25px',
            lineHeight: '20px',
        },
    },
    subSection: {
        fontWeight: 900,
        fontSize: '20.3px',
        color: '#9BA0B7',
        lineHeight: '32px',
        letterSpacing: '1.6px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '20px',
            lineHeight: '30px',
            letterSpacing: '1.5px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '19px',
            lineHeight: '23.5px',
            letterSpacing: '1.35px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '16.5px',
            lineHeight: '18px',
            letterSpacing: '1.2px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '12px',
            lineHeight: '13px',
            letterSpacing: '0.8',
            marginBottom: 0,
        },
    },
    subSectionNested: {
        marginBottom: '40px',
        [theme.breakpoints.down(501)]: { marginBottom: '30px' },
    },
    subSectionIndex: {
        fontWeight: 700,
        fontSize: '18.9px',
        color: '#9BA0B7',   
        lineHeight: '30px',
        letterSpacing: '1.35px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '1.2px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '17px',
            lineHeight: '20px',
            letterSpacing: '1.15px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: '0.65px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '10px',
            lineHeight: '11px',
            marginBottom: 0,
            letterSpacing: '0.4px',
        },
    },
    subSectionIndexNested: {
        fontSize: '19.3px',
        color: '#9BA0B7',
        marginBottom: '45px !important',
        letterSpacing: '1.05px',
        lineHeight: '32px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '19px',
            lineHeight: '30px',
            letterSpacing: '0.9px',
            marginBottom: '20px !important',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '18px',
            lineHeight: '20px',
            letterSpacing: '0.7px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '17px',
            lineHeight: '17.5px',
            letterSpacing: '0.5px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '11px',
            lineHeight: '10px',
            marginBottom: '0 !important',
            letterSpacing: '0.25px',
        },
    },
    subSectionIndexLetters: {
        fontSize: '17.33px',
        color: '#3D4274',
        fontWeight: 'bold',
        margin: '0 0 .8em 0',
        letterSpacing: '1.2px',
        lineHeight: '28px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '19px',
            letterSpacing: '1.1px',
            lineHeight: '25px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '17px',
            lineHeight: '19px',
            letterSpacing: '0.7px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '15px',
            lineHeight: '17px',
            letterSpacing: '0.5px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '0.25px',
        },
    },
    textWrapper: {
        marginBottom: '3.5em',
        columnCount: 2,
        columnGap: '2em',
        columnWidth: '1em',
        '& hr': {
            marginBottom: '18.3px',
            marginTop: '18.3px',
            background: '#3D4274',
            height: '1px',
        },
        [theme.breakpoints.down(1000)]: { marginBottom: '2em' },
        [theme.breakpoints.down(900)]: { columnCount: 1 },
        [theme.breakpoints.down(650)]: { marginBottom: '1em' },
    },
    strong: { fontWeight: 900 },
    attachedText: {
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '0.78px',
        lineHeight: '26.35px',
        fontWeight: 500,
        overflow: 'hidden',
        '& .signature': {
            textJustify: 'right',
            textAlign: 'right',
            float: 'right',
            letterSpacing: 0,    
        },
        '& .under': {
            border: 0,
            borderBottom: '1px solid #3D4274', 
        },
        '& .smol': { fontSize: '13px' },
        [theme.breakpoints.down(1000)]: {
            fontSize: '18px',
            letterSpacing: '0.75px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '17px',
            letterSpacing: '0.34px',
            lineHeight: '26.35px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '16px',
            letterSpacing: '0.5px',
            lineHeight: '22px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px',
            letterSpacing: '0.25px',
            lineHeight: '20px',
        },
    },
    dottedLine: {
        borderTop: '1px dashed',
        background: 'none !important',
        margin: '10px 0 10px 0 !important',
    },
    subSectionIndexContainer: {
        marginTop: '70px',
        marginBottom: '45px',
        [theme.breakpoints.down(1000)]: { marginTop: '40px' },
        [theme.breakpoints.down(650)]: { marginBottom: '30px' },
    },
});
